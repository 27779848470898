import Vue from 'vue'
import AudioVisual from 'vue-audio-visual'
import App from './App.vue'
import store from './store'
import moment from 'moment'
import VueInteractJs from "vue-interactjs";
import Aplayer from 'vue-aplayer';
Vue.use(Aplayer)
Vue.use(AudioVisual)



Vue.use(VueInteractJs)
Vue.prototype.moment = moment
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
